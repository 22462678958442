import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { templateInitialValues, templateSchema } from '../../../utils/validationSchema';
import { updateBroadcastTemplate, getBroadcastTemplateInfoApi } from '../../../api/templateApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import languages from "./languages.json";
import TemplatePreview from '../../common/TemplatePreview';
import Sidebar from '../Sidebar';


function BroadcastTemplateFormEdit() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const [sidebarHideShow, setSidebarHideShow] = useState(true);
    const [buttonTypePreview, setButtonTypePreview] = useState('')
    const [quickReplyPreview, setQuickReplyPreview] = useState([''])
    const fileInputRef = useRef(null)
    const [templatePreview, setTemplatePreview] = useState({ source: 'BroadcastTemplateFormEdit' })
    // extra data for edit functionality
    const { templateId } = useParams();
    const [initialValues, setInitialValues] = useState(templateInitialValues);
    const navigate = useNavigate();

    const getTemplateInfo = async () => {
        setLoading(true);
        try {
            let responceData = await getBroadcastTemplateInfoApi(templateId);
            if (responceData?.data) {
                let prevTemplateInfo = responceData.data;
                setInitialValues({
                    templateName: prevTemplateInfo.name,
                    category: prevTemplateInfo.category,
                    language: prevTemplateInfo.language,
                    header_type: prevTemplateInfo.headerType,
                    header_text: prevTemplateInfo.headerText,
                    header_image: '',
                    header_video: '',
                    header_document: '',
                    body: prevTemplateInfo.body,
                    footer: prevTemplateInfo.footer,
                    button_type: '',
                    quickReplies: [''],
                })
                setTemplatePreview(prevState => ({
                    ...prevState,
                    templateName: prevTemplateInfo.name,
                    headerType: prevTemplateInfo.headerType,
                    headerText: prevTemplateInfo.headerText,
                    headerFile: prevTemplateInfo.headerFile,
                    body: prevTemplateInfo.body,
                    footer: prevTemplateInfo.footer,
                    button_type: '',
                }))
            }

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Get template info failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async (htmlFormData) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', htmlFormData.templateName);
            formData.append('templateType', 'PreApproved');
            formData.append('category', htmlFormData.category);
            formData.append('language', htmlFormData.language);
            formData.append('headerType', htmlFormData.header_type);
            formData.append('headerText', htmlFormData.header_text);

            if (htmlFormData.header_type === "IMAGE") {
                formData.append('headerFile', htmlFormData.header_image);

            } else if (htmlFormData.header_type === "VIDEO") {
                formData.append('headerFile', htmlFormData.header_video);

            } else if (htmlFormData.header_type === "DOCUMENT") {
                formData.append('headerFile', htmlFormData.header_document);
            }
            formData.append('body', htmlFormData.body);
            formData.append('footer', htmlFormData.footer);
            formData.append('createdby', userData._id);
            // update-whatsapp-template
            let responceData = await updateBroadcastTemplate(formData, templateId);
            toast.success('Template updated successful!');
            navigate('/broadcast-template-list')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create template failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event, setFieldValue, fileId) => {
        const file = event.currentTarget.files[0];
        setFieldValue(fileId, file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTemplatePreview(prevState => ({
                    ...prevState,
                    headerFile: reader.result,
                }))
            };
            reader.readAsDataURL(file);
        } else {
            setTemplatePreview(prevState => ({
                ...prevState,
                headerFile: null,
            }))
        }
    };

    const removeFile = (setFieldValue) => {
        setFieldValue("file", null);
        setTemplatePreview(prevState => ({
            ...prevState,
            headerFile: null,
        }))
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    };

    useEffect(() => {
        getTemplateInfo()
    }, [])


    return (<>
        {/* <Header /> */}
        {loading && <Loader />}
        {/* <main className="main mainheight"> */}
        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "padding-top": "calc(env(safe-area-inset-top) + 84.7167px)" }}>
                <div className="container">

                    <div className='headering-back-div'>
                        <h5 className="title">Edit Broadcast Template</h5>
                        <h4>Update template for send <span className="text-gradient">Broadcast</span> to users</h4>

                        <button onClick={(e) => navigate('/broadcast-template-list')} className="btn btn-theme btn-md backButton" >Back</button>
                    </div>
                    <div className="container">

                        <div className="row">
                            {/* Template Form section start */}
                            <div className="col-12 col-md-8">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={templateSchema}
                                    enableReinitialize
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, setFieldValue, values }) => (
                                        // console.log(errors, touched, values)
                                        <Form>
                                            <div className="col-12 ">
                                                <div className="col-12 ">
                                                    <div className="row mb-4">
                                                        <div className="col-12 col-md-6 col-lg-5 ">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field type="text" name="templateName" className="form-control border-start-0" />
                                                                        <label>Template Name <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="templateName" component="div" className="error" />
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-3 ">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="category" className="form-control">
                                                                            <option value='' >Select</option>
                                                                            <option value="MARKETING">Marketing</option>
                                                                            <option value='UTILITY'>Utility</option>

                                                                        </Field>
                                                                        <label>Category <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="category" component="div" className="error" />
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-4 ">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="language" className="form-control">
                                                                            <option value='' >Select</option>
                                                                            {languages.map((languageObj, index) => {
                                                                                return <option key={`lang_${index}`} value={languageObj.Code}>{languageObj.Language}</option>
                                                                            })}

                                                                        </Field>
                                                                        <label>Language <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="language" component="div" className="error" />
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* Header section  start */}
                                                <div className="col-12 ">
                                                    <div className="mb-2">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="select" name="header_type"
                                                                        onChange={(e) => {
                                                                            setFieldValue("header_type", e.target.value);
                                                                            // Clear previews when changing header type
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                headerType: e.target.value,
                                                                                headerText: null,
                                                                                headerFile: null,
                                                                            }))
                                                                        }}
                                                                        className="form-control">
                                                                        <option value='' >None</option>
                                                                        <option value='TEXT' >Text</option>
                                                                        <option value='IMAGE' >Image</option>
                                                                        <option value='VIDEO' >Video</option>
                                                                        <option value='DOCUMENT' >Document</option>
                                                                    </Field>
                                                                    <label>Header (Optional)</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="header_type" component="div" className="error" />
                                                    </div>
                                                </div>
                                                {values.header_type !== '' && (
                                                    <div className="col-12 ">
                                                        {values.header_type === 'TEXT' && (
                                                            <div className="mb-2">
                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                    <div className="input-template input-template-lg">
                                                                        <div className="form-floating">
                                                                            <Field type="text" name="header_text"
                                                                                onChange={(e) => {
                                                                                    setFieldValue('header_text', e.target.value);
                                                                                    setTemplatePreview(prevState => ({
                                                                                        ...prevState,
                                                                                        headerText: e.target.value
                                                                                    }))
                                                                                }}
                                                                                className="form-control border-start-0" />
                                                                            <label>Header Text <span className='star'>*</span></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ErrorMessage name="header_text" component="div" className="error" />
                                                            </div>
                                                        )}

                                                        {values.header_type === 'IMAGE' && (
                                                            <div className='row'>
                                                                <div className='col-6'>

                                                                    <div className="mb-2">
                                                                        <div className="form-template mb-3 position-relative check-valid">
                                                                            <div className="input-template input-template-lg">
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        id="header_image"
                                                                                        name="header_image"
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        onChange={(event) => handleFileChange(event, setFieldValue, 'header_image')}
                                                                                        ref={fileInputRef} // Assign reference to file input
                                                                                        accept=".jpg, .jpeg, .png"
                                                                                    />
                                                                                    <label>Header Image <span className='star'>*</span> (.jpeg, .jpg, .png)</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name="header_image" component="div" className="error" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    {templatePreview.headerFile !== null && (
                                                                        <div className="file-preview" style={{ position: 'relative' }}>
                                                                            <img src={templatePreview.headerFile} alt="Selected File Preview" style={{ width: '50px', height: 'auto', marginBottom: '10px' }} />
                                                                            <span style={{ cursor: 'pointer', position: 'absolute', left: '38px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {values.header_type === 'VIDEO' && (
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className="mb-2">
                                                                        <div className="form-template mb-3 position-relative check-valid">
                                                                            <div className="input-template input-template-lg">
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        id="header_video"
                                                                                        name="header_video"
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        onChange={(event) => handleFileChange(event, setFieldValue, 'header_video')}
                                                                                        ref={fileInputRef} // Assign reference to file input
                                                                                        accept=".mp4"
                                                                                    />
                                                                                    <label>Header Video <span className='star'>*</span> (.mp4)</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name="header_video" component="div" className="error" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    {templatePreview.headerFile !== null && (
                                                                        <div className="file-preview" style={{ position: 'relative' }}>
                                                                            <video controls style={{ width: '200px', height: 'auto', marginBottom: '10px' }}>
                                                                                <source src={templatePreview.headerFile} type="video/mp4" />
                                                                            </video>
                                                                            <span style={{ cursor: 'pointer', position: 'absolute', left: '188px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}


                                                        {values.header_type === 'DOCUMENT' && (
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className="mb-2">
                                                                        <div className="form-template mb-3 position-relative check-valid">
                                                                            <div className="input-template input-template-lg">
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        id="header_document"
                                                                                        name="header_document"
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        onChange={(event) => handleFileChange(event, setFieldValue, 'header_document')}
                                                                                        ref={fileInputRef} // Assign reference to file input
                                                                                        accept=".pdf"
                                                                                    />
                                                                                    <label>Header Document  <span className='star'>*</span> (.pdf)</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name="header_document" component="div" className="error" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    {templatePreview.headerFile !== null && (
                                                                        <div className="file-preview" style={{ position: 'relative' }}>
                                                                            <iframe src={templatePreview.headerFile} alt="Selected File Preview" style={{ width: '50px', height: '60px', marginBottom: '10px' }} />
                                                                            <span style={{ cursor: 'pointer', position: 'absolute', left: '38px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}



                                                    </div>)}
                                                {/* Header section end */}

                                                {/* Body Section start */}
                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="textarea" name="body" className="form-control border-start-0 h-auto" rows="5"
                                                                        onChange={(e) => {
                                                                            setFieldValue('body', e.target.value);
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                body: e.target.value,
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <label>Body <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="body" component="div" className="error" />
                                                    </div>
                                                </div>
                                                {/* Body section end */}

                                                {/* Footer Section start */}
                                                <div className="col-12 ">
                                                    <div className="mb-2">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field type="text" name="footer"
                                                                        onChange={(e) => {
                                                                            setFieldValue('footer', e.target.value);
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                footer: e.target.value,
                                                                            }))
                                                                        }}
                                                                        className="form-control border-start-0" />
                                                                    <label>Footer (Optional)</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="footer" component="div" className="error" />
                                                    </div>
                                                </div>
                                                {/* Footer section end */}

                                                {/* Button section start */}
                                                {/* <div className="col-12 ">
                                                <div className="mb-2">
                                                    <div className="form-template mb-3 position-relative check-valid">
                                                        <div className="input-template input-template-lg">
                                                            <div className="form-floating">
                                                                <Field as="select"
                                                                    onChange={(e) => {
                                                                        setFieldValue('button_type', e.target.value)
                                                                        setButtonTypePreview(e.target.value)
                                                                    }}
                                                                    name="button_type" className="form-control">
                                                                    <option value='' >None</option>
                                                                    <option value="call_to_action">Call to Action</option>
                                                                    <option value='quick_reply'>Quick Reply</option>

                                                                </Field>
                                                                <label>Button (Optional)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="button_type" component="div" className="error" />
                                                </div>
                                            </div> */}
                                                {values.button_type !== '' && (
                                                    values.button_type === "quick_reply" && (
                                                        <FieldArray name="quickReplies">
                                                            {({ insert, remove, push }) => (
                                                                <div className="col-12 ">

                                                                    {values.quickReplies && values.quickReplies.length > 0
                                                                        ? values.quickReplies.map((reply, index) => (
                                                                            <div className="row mb-4" key={index}>
                                                                                <div className="col-12 col-md-8">
                                                                                    <div className="mb-2">
                                                                                        <div className="form-template mb-3 position-relative check-valid">
                                                                                            <div className="input-template input-template-lg">
                                                                                                <div className="form-floating">
                                                                                                    <Field type="text" name={`quickReplies.${index}`}
                                                                                                        onChange={(e) => {
                                                                                                            // Update the field value
                                                                                                            setFieldValue(`quickReplies.${index}`, e.target.value);

                                                                                                            // Update the quickReplyPreview array
                                                                                                            const newPreview = [...values.quickReplies];
                                                                                                            newPreview[index] = e.target.value;
                                                                                                            setQuickReplyPreview(newPreview);
                                                                                                        }}
                                                                                                        className="form-control border-start-0"
                                                                                                        maxlength="25"
                                                                                                    />
                                                                                                    <label>Button{index + 1}<span className='star'>*</span></label>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <ErrorMessage name={`quickReplies.${index}`} component="div" className="error" />
                                                                                    </div>
                                                                                    {values.quickReplies.length - 1 === index && index < 2 && (
                                                                                        <button className="btn btn-sm btn-info" type="button"
                                                                                            onClick={() => {
                                                                                                push('')
                                                                                                setQuickReplyPreview([...quickReplyPreview, ''])
                                                                                            }}>Add Button +{2 - index}</button>
                                                                                    )}

                                                                                </div>
                                                                                {values.quickReplies.length > 1 ? (
                                                                                    <div className="col-12 col-md-4 ">
                                                                                        {`${reply.length}/25`}<br />
                                                                                        <button className="btn btn-sm btn-danger " type="button"
                                                                                            onClick={() => {
                                                                                                remove(index);
                                                                                                setQuickReplyPreview(preValue => {
                                                                                                    let newData = preValue.filter((val, loopIndex) => loopIndex !== index)
                                                                                                    return newData;
                                                                                                })
                                                                                            }} >X</button>
                                                                                    </div>
                                                                                ) : (<div className="col-12 col-md-4 ">
                                                                                    {`${reply.length}/25`}
                                                                                </div>)}


                                                                            </div>
                                                                        ))
                                                                        : null}


                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                    )

                                                )}
                                                {/* Button section end */}

                                                <div style={{ display: 'flex' }}>
                                                    <button style={{ height: '36px', marginRight: '30px' }} className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />


                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            {/* Template Form section end */}

                            {/* Template Preview section start */}
                            <div className="col-12 col-md-4">
                                <TemplatePreview templatePreview={templatePreview} />
                            </div>
                            {/* Template Preview section end */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className=' className="col-6 col-md-6"'>

                        </div>
                        <div className='className="col-6 col-md-6"'>

                        </div>
                    </div>

                </div>
            </main >
        </div>
        <Footer />

    </>)
}

export default BroadcastTemplateFormEdit;