import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/growwuo_logo.png'


function TermCondition() {
    const navigate = useNavigate();

    return (<>



        <nav id="mainNav" className="sidebar sidebar-default sidebar-fixed-top">
            <div className="container">
                <div className="sidebar-header">

                    <a className="sidebar-brand" href="#"><img src={Logo} alt="Logo" width={200} />
                    </a>
                </div>


                <div className="collapse sidebar-collapse">
                    <ul className="side sidebar-side sidebar-rights">
                        <li>
                            <a className="page-scroll" onClick={(e) => navigate('/')}  >HOME</a>
                        </li>


                    </ul>
                </div>

            </div>
        </nav>

        <section id="about" className="section-padding about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title" style={{ "text-align": "justify" }}>
                                    <h2>Terms <span>Conditions</span></h2>
                                    <h4>Last Updated: 01-12-2024</h4>
                                    <p>Welcome to Growwup! These Terms and Conditions govern your use of our website, services, and products. By accessing or using our website located at growwups.in (the "Website") or any of our services, you agree to comply with these Terms and Conditions. If you do not agree, please do not use our services.</p>
                                    <h4>1. Acceptance of Terms</h4>
                                    <p>By using Growwup’s services, you confirm that you are at least 18 years old and legally capable of entering into binding contracts. If you are using our services on behalf of an organization, you agree to these terms on behalf of that organization.</p>
                                    <h4>2. Services Provided</h4>
                                    <p>Growwup offers digital marketing services, including but not limited to:</p>
                                    <p>WhatsApp bulk messaging using the Business API.</p>
                                    <p>Broadcast messaging solutions.</p>
                                    <p>Chatbot development and deployment.</p>
                                    <p>Additional services related to digital marketing campaigns.</p>
                                    <p>All services are subject to these Terms and our Privacy Policy.</p>
                                    <h4>3. User Responsibilities</h4>
                                    <p>When using our services, you agree:</p>
                                    <p>To provide accurate, current, and complete information as requested.</p>
                                    <p>To comply with all applicable laws and regulations, including but not limited to data protection and anti-spam laws.</p>
                                    <p>Not to use Growwup’s services for unlawful or prohibited activities, including sending unsolicited messages or promoting illegal content.</p>
                                    <p>To secure any necessary permissions or consents from your contacts before using our services to communicate with them.</p>
                                    <h4>4. Intellectual Property</h4>
                                    <p>All content, materials, and intellectual property available on our website or provided through our services are owned by Growwup or our licensors. You may not reproduce, distribute, or use any content without prior written consent.</p>
                                    <h4>5. Payment and Refunds</h4>
                                    <p>Payments for our services must be made in accordance with the pricing and terms provided at the time of purchase.</p>
                                    <p>Refunds: Refunds are issued only at Growwup’s discretion and in compliance with our refund policy.</p>
                                    <p>Late Payments: Late payments may result in the suspension or termination of your access to services.</p>
                                    <h4>6. Privacy</h4>
                                    <p>Your use of our website and services is subject to our <a href='https://growwups.in/privacy-policy' target="_blank"> Privacy Policy</a>. By using our services, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.</p>
                                    <h4>7. Limitations of Liability</h4>
                                    <p>Growwup shall not be held liable for:</p>
                                    <p>Any damages resulting from unauthorized use of our services.</p>
                                    <p>Interruption or unavailability of our services due to technical issues.</p>
                                    <p>Loss or corruption of data during the use of our services.</p>
                                    <p>To the maximum extent permitted by law, Growwup’s liability is limited to the amount paid by you for the service in question.</p>
                                    <h4>8. Third-Party Services</h4>
                                    <p>Our services may include integrations with third-party platforms like WhatsApp. Use of these platforms is subject to their respective terms and policies, and Growwup is not responsible for third-party actions.</p>
                                    <h4>9. Termination</h4>
                                    <p>We reserve the right to suspend or terminate your access to our services if you violate these Terms, misuse the services, or engage in prohibited activities.</p>
                                    <h4>10. Changes to Terms</h4>

                                    <p>Growwup reserves the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting on our website. It is your responsibility to review the Terms periodically.</p>
                                    <h4>11. Contact Information</h4>
                                    <p>For questions or concerns about these Terms and Conditions, please contact us at:</p>
                                    <p>Email: admin@growwups.in</p>
                                    <p>Phone: +91 89596971763</p>
                                    <p>Website: growwups.in</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <footer className="section-padding footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-padding">
                            <div className="row">
                                <div className="col-md-12 text-center">

                                    <div className="row">
                                        <div className="col-md-6 copyright">
                                            <p>2024 <span>&copy;</span> Growwups</p>
                                        </div>
                                        <div className="col-md-6 copyright">
                                            <p>Privacy Policy <a className='class="btn-wt-bdr2"' onClick={(e) => navigate('/privacy-policy')}  >click here</a> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    </>)
}
export default TermCondition;