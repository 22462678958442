import { useEffect, useState } from "react";
import * as Icon from 'react-bootstrap-icons';

const TemplatePreview = ({ templatePreview, source = 'Other' }) => {
    const [headerType, setHeaderType] = useState('')
    const [headerText, setHeaderText] = useState('')
    const [headerFile, setHeaderFile] = useState('')
    const [bodyPreview, setBodyPreview] = useState('');
    const [footerPreview, setFooterPreview] = useState('')
    const [buttonTypePreview, setButtonTypePreview] = useState([])



    useEffect(() => {
        // console.log('TemplatePreview...................');
        if (templatePreview) {
            // console.log('TemplatePreview:templatePreview', templatePreview);
            setHeaderType(templatePreview.headerType)
            setHeaderText(templatePreview.headerText)
            setHeaderFile(templatePreview.headerFile)
            setBodyPreview(templatePreview.body)
            setFooterPreview(templatePreview.footer)
        }
    })

    const handleJson = (postData) => {
        console.log('postData', postData)
    }

    return (
        source !== 'chatList' ?
            (<section className="msger">
                <header className="msger-header">
                    <div className="msger-header-title">
                        <i className="fas fa-comment-alt"></i> Chat Preview
                    </div>
                    <div className="msger-header-options">
                        <span><i className="fas fa-cog"></i></span>
                    </div>
                </header>

                <main className="msger-chat">
                    <div className="msg left-msg">
                        <div

                        ><Icon.PersonBoundingBox className="msg-img" /></div>

                        <div className="msg-bubble">
                            <div className="msg-info">
                                <div className="msg-info-name">BOT</div>
                                {/* <div className="msg-info-time">12:45</div> */}
                            </div>

                            <div className="msg-text" style={{ whiteSpace: 'pre-wrap' }}>
                                {headerType === "IMAGE" && headerFile !== null && (
                                    <>
                                        <img src={headerFile} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                        <br />
                                    </>
                                )}

                                {headerType === "VIDEO" && headerFile !== null && (
                                    <>
                                        <video controls style={{ width: '200px', height: '200px', marginBottom: '10px' }}>
                                            <source src={headerFile} type="video/mp4" />

                                        </video>
                                        <br />
                                    </>
                                )}

                                {headerType === "DOCUMENT" && headerFile !== null && (
                                    <>
                                        <iframe
                                            src={headerFile}
                                            style={{ width: '200px', height: '200px' }}
                                            title="Document Preview"
                                        />
                                        <br />
                                    </>
                                )}

                                {headerType === "TEXT" && <><b>{headerText}</b><br /><br /></>}
                                {bodyPreview && <>{bodyPreview}<br /><br /></>}
                                {footerPreview && <>{footerPreview}<br /><br /></>}

                                {templatePreview.buttonType && templatePreview.buttonType.includes("quickReply") && templatePreview?.quickReplies?.length > 0 &&
                                    <>
                                        <p>Quick Reply</p>
                                        {(
                                            templatePreview?.quickReplies?.length > 0 && templatePreview['quickReplies'].map((button, index) => {
                                                return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.replyButton && `Button${index + 1}`}  {button.replyButton}</button>
                                            })
                                        )}
                                    </>
                                }

                                {templatePreview.buttonType && templatePreview.buttonType.includes("visitWebsite") && templatePreview?.visitWebsiteButtons.length > 0 &&
                                    <>
                                        <p>Visit Website</p>
                                        {templatePreview?.visitWebsiteButtons.length > 0 && templatePreview['visitWebsiteButtons'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                        })}
                                    </>
                                }

                                {templatePreview.buttonType && templatePreview.buttonType.includes("callPhoneNumber") && templatePreview?.callPhoneNumberButtons.length > 0 &&
                                    <>
                                        <p>Call Phone Number</p>
                                        {templatePreview['callPhoneNumberButtons'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                        })}
                                    </>
                                }
                                {templatePreview.buttonType && templatePreview.buttonType.includes("copyOfferCode") && templatePreview?.copyOfferCodeButtons.length > 0 &&
                                    <>
                                        <p>Copy Offer Code</p>
                                        {templatePreview['copyOfferCodeButtons'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                        })}
                                    </>
                                }

                                {/* {templatePreview.buttonType && templatePreview.buttonType.includes("quickReply") && (templatePreview?.quickReply?.length > 0 || templatePreview?.quickReplies?.length > 0) &&
                                    <>
                                        <p>Quick Reply</p>
                                        {templatePreview?.quickReply?.length && templatePreview['quickReply'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.replyButton && `Button${index + 1}`}  {button.replyButton}</button>
                                        })}
                                        {templatePreview?.quickReplies?.length && templatePreview['quickReplies'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.replyButton && `Button${index + 1}`}  {button.replyButton}</button>
                                        })}
                                    </>
                                }

                                {templatePreview.buttonType && templatePreview.buttonType.includes("visitWebsite") && templatePreview['visitWebsite'].length > 0 &&
                                    <>
                                        <p>Visit Website</p>
                                        {templatePreview['visitWebsite'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                        })}
                                    </>
                                }

                                {templatePreview.buttonType && templatePreview.buttonType.includes("callPhoneNumber") && templatePreview['callPhoneNumber'].length > 0 &&
                                    <>
                                        <p>Call Phone Number</p>
                                        {templatePreview['callPhoneNumber'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                        })}
                                    </>
                                }
                                {templatePreview.buttonType && templatePreview.buttonType.includes("copyOfferCode") && templatePreview['copyOfferCode'].length > 0 &&
                                    <>
                                        <p>Copy Offer Code</p>
                                        {templatePreview['copyOfferCode'].map((button, index) => {
                                            return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                        })}
                                    </>
                                } */}
                            </div>
                        </div>
                    </div>
                </main>

                <form className="msger-inputarea">
                    <input type="text" className="msger-input" placeholder="Enter your message..." disabled />
                    <button type="submit" className="msger-send-btn" disabled>Send</button>
                </form>
            </section>) : (

                <div className="msg-bubble">
                    <div className="msg-text" style={{ whiteSpace: 'pre-wrap' }}>
                        {headerType === "IMAGE" && headerFile !== null && (
                            <>
                                <img src={headerFile} alt="Selected File Preview" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                <br />
                            </>
                        )}

                        {headerType === "VIDEO" && headerFile !== null && (
                            <>
                                <video controls style={{ width: '200px', height: '200px', marginBottom: '10px' }}>
                                    <source src={headerFile} type="video/mp4" />

                                </video>
                                <br />
                            </>
                        )}

                        {headerType === "DOCUMENT" && headerFile !== null && (
                            <>
                                <iframe
                                    src={headerFile}
                                    style={{ width: '200px', height: '200px' }}
                                    title="Document Preview"
                                />
                                <br />
                            </>
                        )}

                        {headerType === "TEXT" && <><b>{headerText}</b><br /><br /></>}
                        {bodyPreview && <>    {bodyPreview.length > 200 ? `${bodyPreview.substring(0, 200)}...` : bodyPreview}                        <br /><br /></>}
                        {footerPreview && <>{footerPreview}<br /><br /></>}

                        {templatePreview.buttonType && templatePreview.buttonType.includes("quickReply") && templatePreview?.quickReplies?.length > 0 &&
                            <>
                                <p>Quick Reply</p>
                                {(
                                    templatePreview?.quickReplies?.length > 0 && templatePreview['quickReplies'].map((button, index) => {
                                        return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.replyButton && `Button${index + 1}`}  {button.replyButton}</button>
                                    })
                                )}
                            </>
                        }

                        {templatePreview.buttonType && templatePreview.buttonType.includes("visitWebsite") && templatePreview?.visitWebsiteButtons.length > 0 &&
                            <>
                                <p>Visit Website</p>
                                {templatePreview?.visitWebsiteButtons.length > 0 && templatePreview['visitWebsiteButtons'].map((button, index) => {
                                    return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                })}
                            </>
                        }

                        {templatePreview.buttonType && templatePreview.buttonType.includes("callPhoneNumber") && templatePreview?.callPhoneNumberButtons.length > 0 &&
                            <>
                                <p>Call Phone Number</p>
                                {templatePreview['callPhoneNumberButtons'].map((button, index) => {
                                    return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                })}
                            </>
                        }
                        {templatePreview.buttonType && templatePreview.buttonType.includes("copyOfferCode") && templatePreview?.copyOfferCodeButtons.length > 0 &&
                            <>
                                <p>Copy Offer Code</p>
                                {templatePreview['copyOfferCodeButtons'].map((button, index) => {
                                    return <button key={index} type="button" className="msger-send-btn mb-1 " >{!button.buttonText && `Button${index + 1}`}  {button.buttonText}</button>
                                })}
                            </>
                        }
                    </div>
                </div>

            )
    )
}

export default TemplatePreview;