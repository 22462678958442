import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';

import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { templateInitialValuesNew, templateSchemaNew } from '../../../utils/validationSchema';
import { updateBroadcastTemplate, getBroadcastTemplateInfoApi } from '../../../api/templateApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import languages from "./languages.json";
import TemplatePreview from '../../common/TemplatePreview';
import Sidebar from '../Sidebar';


function BroadcastTemplateFormButtonEdit() {
    const [loading, setLoading] = useState(false);
    const { userData } = useAuthContext();
    const fileInputRef = useRef(null)
    const [sidebarHideShow, setSidebarHideShow] = useState(true);
    const [templatePreview, setTemplatePreview] = useState({ source: 'BroadcastTemplateFormEdit' })
    // extra data for edit functionality
    const { templateId } = useParams();
    const [initialValues, setInitialValues] = useState(templateInitialValuesNew);
    const navigate = useNavigate();

    // button edit functionality
    const [selectedButtonType, setSelectedButtonType] = useState([]);
    const [btnLimit, setBtnLimit] = useState({
        //limit
        templateMaxButtonLimit: 10,
        quickReplyMaxBtnLimit: 10,
        visitWebsiteMaxBtnLimit: 2,
        callPhoneNumberMaxBtnLimit: 1,
        copyOfferCodeMaxBtnLimit: 1,
        // can add, we conditionaly minus  this as different type of buttons added
        templateAddedBtn: 0,
        quickReplyAddBtn: 10,
        visitWebsiteAddBtn: 2,
        callPhoneNumberAddBtn: 1,
        copyOfferCodeAddBtn: 1,
    });

    const getTemplateInfo = async () => {
        setLoading(true);
        try {
            let responceData = await getBroadcastTemplateInfoApi(templateId);
            if (responceData?.data) {
                let prevTemplateInfo = responceData.data;
                console.log('prevTemplateInfo', prevTemplateInfo);

                setInitialValues({
                    templateName: prevTemplateInfo.name,
                    category: prevTemplateInfo.category,
                    language: prevTemplateInfo.language,
                    header_type: prevTemplateInfo.headerType,
                    header_text: prevTemplateInfo.headerText,
                    header_image: '',
                    header_video: '',
                    header_document: '',
                    body: prevTemplateInfo.body,
                    footer: prevTemplateInfo.footer,
                    buttonType: prevTemplateInfo?.buttonType ? prevTemplateInfo?.buttonType : [],
                    quickReplies: prevTemplateInfo?.quickReplies ? prevTemplateInfo?.quickReplies : [{
                        "replyButton": ""
                    }],
                    visitWebsiteButtons: prevTemplateInfo?.visitWebsiteButtons ? prevTemplateInfo?.visitWebsiteButtons : [{
                        "buttonText": "",
                        "websiteUrl": ""
                    }],
                    callPhoneNumberButtons: prevTemplateInfo?.callPhoneNumberButtons ? prevTemplateInfo?.callPhoneNumberButtons : [{
                        "buttonText": "",
                        "countryCode": "",
                        "phoneNumber": ""
                    }],
                    copyOfferCodeButtons: prevTemplateInfo?.copyOfferCodeButtons ? prevTemplateInfo?.copyOfferCodeButtons : [{
                        "buttonText": "Copy offer code",
                        "offerCode": ""
                    }],
                })
                setTemplatePreview(prevState => ({
                    ...prevState,
                    templateName: prevTemplateInfo.name,
                    headerType: prevTemplateInfo.headerType,
                    headerText: prevTemplateInfo.headerText,
                    headerFile: prevTemplateInfo.headerFile,
                    body: prevTemplateInfo.body,
                    footer: prevTemplateInfo.footer,
                    buttonType: prevTemplateInfo?.buttonType ? prevTemplateInfo?.buttonType : [],
                    quickReplies: prevTemplateInfo?.quickReplies ? prevTemplateInfo?.quickReplies : [{
                        "replyButton": ""
                    }],
                    visitWebsiteButtons: prevTemplateInfo?.visitWebsiteButtons ? prevTemplateInfo?.visitWebsiteButtons : [{
                        "buttonText": "",
                        "websiteUrl": ""
                    }],
                    callPhoneNumberButtons: prevTemplateInfo?.callPhoneNumberButtons ? prevTemplateInfo?.callPhoneNumberButtons : [{
                        "buttonText": "",
                        "countryCode": "",
                        "phoneNumber": ""
                    }],
                    copyOfferCodeButtons: prevTemplateInfo?.copyOfferCodeButtons ? prevTemplateInfo?.copyOfferCodeButtons : [{
                        "buttonText": "Copy offer code",
                        "offerCode": ""
                    }],
                }))
                setSelectedButtonType(prevTemplateInfo?.buttonType ? prevTemplateInfo?.buttonType : [])
            }

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Get template info failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }
    const handleSubmit = async (htmlFormData) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('name', htmlFormData.templateName);
            formData.append('templateType', 'PreApproved');
            formData.append('category', htmlFormData.category);
            formData.append('language', htmlFormData.language);
            formData.append('headerType', htmlFormData.header_type);
            formData.append('headerText', htmlFormData.header_text);

            if (htmlFormData.header_type === "IMAGE") {
                formData.append('headerFile', htmlFormData.header_image);

            } else if (htmlFormData.header_type === "VIDEO") {
                formData.append('headerFile', htmlFormData.header_video);

            } else if (htmlFormData.header_type === "DOCUMENT") {
                formData.append('headerFile', htmlFormData.header_document);
            }
            formData.append('body', htmlFormData.body);
            formData.append('footer', htmlFormData.footer);
            formData.append('createdby', userData._id);

            formData.append('buttonType', JSON.stringify(htmlFormData.buttonType));

            if (htmlFormData.buttonType?.includes("quickReply")) {
                formData.append('quickReplies', JSON.stringify(htmlFormData.quickReplies));
            }
            if (htmlFormData.buttonType?.includes("visitWebsite")) {
                formData.append('visitWebsiteButtons', JSON.stringify(htmlFormData.visitWebsiteButtons));
            }
            if (htmlFormData.buttonType?.includes("callPhoneNumber")) {
                formData.append('callPhoneNumberButtons', JSON.stringify(htmlFormData.callPhoneNumberButtons));
            }
            if (htmlFormData.buttonType?.includes("copyOfferCode")) {
                formData.append('copyOfferCodeButtons', JSON.stringify(htmlFormData.copyOfferCodeButtons));
            }

            // console.log('htmlFormData',htmlFormData);

            // update-whatsapp-template
            let responceData = await updateBroadcastTemplate(formData, templateId);
            toast.success('Template updated successful!');
            navigate('/broadcast-template-list')

        } catch (error) {
            let errorMessage = error.response?.data?.ErrorMessage || 'Update template failed';
            if (errorMessage === "failure") {
                // console.log('errorMessage01',error.response?.data?.error?.error_user_msg);
                if (error.response?.data?.error?.error_user_msg) {
                    errorMessage = error.response?.data?.error?.error_user_msg
                }
                toast.error(errorMessage);
            } else {
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event, setFieldValue, fileId) => {
        const file = event.currentTarget.files[0];
        setFieldValue(fileId, file);

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setTemplatePreview(prevState => ({
                    ...prevState,
                    headerFile: reader.result,
                }))
            };
            reader.readAsDataURL(file);
        } else {
            setTemplatePreview(prevState => ({
                ...prevState,
                headerFile: null,
            }))
        }
    };

    const removeFile = (setFieldValue) => {
        setFieldValue("file", null);
        setTemplatePreview(prevState => ({
            ...prevState,
            headerFile: null,
        }))
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    };

    useEffect(() => {
        getTemplateInfo()
    }, [])

    useEffect(() => {
        // delete key from prev if button type unselected
        if (templatePreview['buttonType'] && !templatePreview['buttonType'].includes('quickReply')) {
            delete templatePreview['quickReply']
        }
        if (templatePreview['buttonType'] && !templatePreview['buttonType'].includes('visitWebsite')) {
            delete templatePreview['visitWebsite']
        }
        if (templatePreview['buttonType'] && !templatePreview['buttonType'].includes('callPhoneNumber')) {
            delete templatePreview['callPhoneNumber']
        }
    }, [templatePreview.buttonType])

    useEffect(() => {
        let quickReplyLength = templatePreview.buttonType && templatePreview.buttonType.includes("quickReply") && templatePreview.quickReplies ? templatePreview.quickReplies.length : 0;
        let visitWebsiteLength = templatePreview.buttonType && templatePreview.buttonType.includes("visitWebsite") && templatePreview.visitWebsiteButtons ? templatePreview.visitWebsiteButtons.length : 0;
        let callPhoneNumberLength = templatePreview.buttonType && templatePreview.buttonType.includes("callPhoneNumber") && templatePreview.callPhoneNumberButtons ? templatePreview.callPhoneNumberButtons.length : 0;
        let copyOfferCodeLength = templatePreview.buttonType && templatePreview.buttonType.includes("copyOfferCode") && templatePreview.copyOfferCodeButtons ? templatePreview.copyOfferCodeButtons.length : 0;
        let templateAddedBtn = quickReplyLength + visitWebsiteLength + callPhoneNumberLength + copyOfferCodeLength;

        // calculate for quickReplyAddBtn
        let quickReplyAddBtn = btnLimit.quickReplyMaxBtnLimit - templateAddedBtn;

        console.log('////////////////////////////////////////////////');
        console.log('quickReplyLength', quickReplyLength);
        console.log('visitWebsiteLength', visitWebsiteLength);
        console.log('callPhoneNumberLength', callPhoneNumberLength);
        console.log('copyOfferCodeLength', copyOfferCodeLength);
        console.log('templateAddedBtn', templateAddedBtn);
        console.log('templatePreview', templatePreview);
        console.log('templatePreview.buttonType', templatePreview.buttonType);




        // calculate for visitWebsiteAddBtn
        let visitWebsiteAddBtn = btnLimit.visitWebsiteMaxBtnLimit - visitWebsiteLength;
        if (visitWebsiteAddBtn > 0 && templateAddedBtn === btnLimit.templateMaxButtonLimit) {
            visitWebsiteAddBtn = 0
        }

        // calculate for callPhoneNumberAddBtn
        let callPhoneNumberAddBtn = btnLimit.callPhoneNumberMaxBtnLimit - callPhoneNumberLength;
        if (callPhoneNumberAddBtn > 0 && templateAddedBtn === btnLimit.templateMaxButtonLimit) {
            callPhoneNumberAddBtn = 0
        }

        // calculate for copyOfferCodeAddBtn
        let copyOfferCodeAddBtn = btnLimit.copyOfferCodeMaxBtnLimit - copyOfferCodeLength;
        if (copyOfferCodeAddBtn > 0 && templateAddedBtn === btnLimit.templateMaxButtonLimit) {
            copyOfferCodeAddBtn = 0
        }

        setBtnLimit(prev => ({
            ...prev,
            templateAddedBtn: templateAddedBtn,
            quickReplyAddBtn: quickReplyAddBtn,
            visitWebsiteAddBtn: visitWebsiteAddBtn,
            callPhoneNumberAddBtn: callPhoneNumberAddBtn,
            copyOfferCodeAddBtn: copyOfferCodeAddBtn
        }))

    }, [templatePreview.quickReplies, templatePreview.visitWebsiteButtons, templatePreview.callPhoneNumberButtons, templatePreview.copyOfferCodeButtons, templatePreview.buttonType])


    return (<>
        {/* <Header /> */}
        {loading && <Loader />}
        {/* <main className="main mainheight"> */}
        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "padding-top": "calc(env(safe-area-inset-top) + 84.7167px)" }}>
                <div className="container-fluid mb-4">
                    <div className="row align-items-center page-title">
                        <div className="col col-sm-auto">
                            <div className="input-group input-group-md">
                                <span className="input-group-text text-secondary bg-none" >Edit Broadcast Template </span>
                            </div>
                        </div>
                        <div className="col col-sm-auto">
                            <h4>Update template for send <span className="text-gradient">Broadcast</span> to users</h4>


                        </div>
                        <div className="col-auto sm-auto">
                            <button onClick={(e) => navigate('/broadcast-template-list')} className="btn btn-theme btn-md" >Back</button>
                        </div>
                    </div>
                </div>
                <div className="container">

                    {/* <div className='headering-back-div'>
                        <h5 className="title">Edit Broadcast Template 01</h5>
                        <h4>Update template for send <span className="text-gradient">Broadcast</span> to users</h4>

                        <button onClick={(e) => navigate('/broadcast-template-list')} className="btn btn-theme btn-md backButton" >Back</button>
                    </div> */}
                    <div className="container">

                        <div className="row">
                            {/* Template Form section start */}
                            <div className="col-12 col-md-8">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={templateSchemaNew}
                                    enableReinitialize
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, setFieldValue, values }) => (
                                        // console.log(errors, touched, values)
                                        <Form>
                                            <div className="col-12 ">
                                                <div className="col-12 ">
                                                    <div className="row mb-4">
                                                        <div className="col-12 col-md-6 col-lg-5 ">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field type="text" name="templateName" className="form-control border-start-0" />
                                                                        <label>Template Name <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="templateName" component="div" className="error" />
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-3 ">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="category" className="form-control">
                                                                            <option value='' >Select</option>
                                                                            <option value="MARKETING">Marketing</option>
                                                                            <option value='UTILITY'>Utility</option>

                                                                        </Field>
                                                                        <label>Category <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="category" component="div" className="error" />
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-4 ">
                                                            <div className="form-template mb-3 position-relative check-valid">
                                                                <div className="input-template input-template-lg">
                                                                    <div className="form-floating">
                                                                        <Field as="select" name="language" className="form-control">
                                                                            <option value='' >Select</option>
                                                                            {languages.map((languageObj, index) => {
                                                                                return <option key={`lang_${index}`} value={languageObj.Code}>{languageObj.Language}</option>
                                                                            })}

                                                                        </Field>
                                                                        <label>Language <span className='star'>*</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="language" component="div" className="error" />
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* Header section  start */}
                                                <div className="col-12 ">
                                                    <div className="mb-2">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="select" name="header_type"
                                                                        onChange={(e) => {
                                                                            setFieldValue("header_type", e.target.value);
                                                                            // Clear previews when changing header type
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                headerType: e.target.value,
                                                                                headerText: null,
                                                                                headerFile: null,
                                                                            }))
                                                                        }}
                                                                        className="form-control">
                                                                        <option value='' >None</option>
                                                                        <option value='TEXT' >Text</option>
                                                                        <option value='IMAGE' >Image</option>
                                                                        <option value='VIDEO' >Video</option>
                                                                        <option value='DOCUMENT' >Document</option>
                                                                    </Field>
                                                                    <label>Header (Optional)</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="header_type" component="div" className="error" />
                                                    </div>
                                                </div>
                                                {values.header_type !== '' && (
                                                    <div className="col-12 ">
                                                        {values.header_type === 'TEXT' && (
                                                            <div className="mb-2">
                                                                <div className="form-template mb-3 position-relative check-valid">
                                                                    <div className="input-template input-template-lg">
                                                                        <div className="form-floating">
                                                                            <Field type="text" name="header_text"
                                                                                onChange={(e) => {
                                                                                    setFieldValue('header_text', e.target.value);
                                                                                    setTemplatePreview(prevState => ({
                                                                                        ...prevState,
                                                                                        headerText: e.target.value
                                                                                    }))
                                                                                }}
                                                                                className="form-control border-start-0" />
                                                                            <label>Header Text <span className='star'>*</span></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ErrorMessage name="header_text" component="div" className="error" />
                                                            </div>
                                                        )}

                                                        {values.header_type === 'IMAGE' && (
                                                            <div className='row'>
                                                                <div className='col-6'>

                                                                    <div className="mb-2">
                                                                        <div className="form-template mb-3 position-relative check-valid">
                                                                            <div className="input-template input-template-lg">
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        id="header_image"
                                                                                        name="header_image"
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        onChange={(event) => handleFileChange(event, setFieldValue, 'header_image')}
                                                                                        ref={fileInputRef} // Assign reference to file input
                                                                                        accept=".jpg, .jpeg, .png"
                                                                                    />
                                                                                    <label>Header Image <span className='star'>*</span> (.jpeg, .jpg, .png)</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name="header_image" component="div" className="error" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    {templatePreview.headerFile !== null && (
                                                                        <div className="file-preview" style={{ position: 'relative' }}>
                                                                            <img src={templatePreview.headerFile} alt="Selected File Preview" style={{ width: '50px', height: 'auto', marginBottom: '10px' }} />
                                                                            <span style={{ cursor: 'pointer', position: 'absolute', left: '38px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {values.header_type === 'VIDEO' && (
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className="mb-2">
                                                                        <div className="form-template mb-3 position-relative check-valid">
                                                                            <div className="input-template input-template-lg">
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        id="header_video"
                                                                                        name="header_video"
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        onChange={(event) => handleFileChange(event, setFieldValue, 'header_video')}
                                                                                        ref={fileInputRef} // Assign reference to file input
                                                                                        accept=".mp4"
                                                                                    />
                                                                                    <label>Header Video <span className='star'>*</span> (.mp4)</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name="header_video" component="div" className="error" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    {templatePreview.headerFile !== null && (
                                                                        <div className="file-preview" style={{ position: 'relative' }}>
                                                                            <video controls style={{ width: '200px', height: 'auto', marginBottom: '10px' }}>
                                                                                <source src={templatePreview.headerFile} type="video/mp4" />
                                                                            </video>
                                                                            <span style={{ cursor: 'pointer', position: 'absolute', left: '188px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}


                                                        {values.header_type === 'DOCUMENT' && (
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className="mb-2">
                                                                        <div className="form-template mb-3 position-relative check-valid">
                                                                            <div className="input-template input-template-lg">
                                                                                <div className="form-floating">
                                                                                    <input
                                                                                        id="header_document"
                                                                                        name="header_document"
                                                                                        type="file"
                                                                                        className="form-control"
                                                                                        onChange={(event) => handleFileChange(event, setFieldValue, 'header_document')}
                                                                                        ref={fileInputRef} // Assign reference to file input
                                                                                        accept=".pdf"
                                                                                    />
                                                                                    <label>Header Document  <span className='star'>*</span> (.pdf)</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage name="header_document" component="div" className="error" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    {templatePreview.headerFile !== null && (
                                                                        <div className="file-preview" style={{ position: 'relative' }}>
                                                                            <iframe src={templatePreview.headerFile} alt="Selected File Preview" style={{ width: '50px', height: '60px', marginBottom: '10px' }} />
                                                                            <span style={{ cursor: 'pointer', position: 'absolute', left: '38px', background: 'red', padding: '2px' }} onClick={() => removeFile(setFieldValue)}>X</span>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}



                                                    </div>)}
                                                {/* Header section end */}

                                                {/* Body Section start */}
                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="textarea" name="body" className="form-control border-start-0 h-auto" rows="5"
                                                                        onChange={(e) => {
                                                                            setFieldValue('body', e.target.value);
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                body: e.target.value,
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <label>Body <span className='star'>*</span></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="body" component="div" className="error" />
                                                    </div>
                                                </div>
                                                {/* Body section end */}

                                                {/* Footer Section start */}
                                                <div className="col-12 ">
                                                    <div className="mb-2">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field type="text" name="footer"
                                                                        onChange={(e) => {
                                                                            setFieldValue('footer', e.target.value);
                                                                            setTemplatePreview(prevState => ({
                                                                                ...prevState,
                                                                                footer: e.target.value,
                                                                            }))
                                                                        }}
                                                                        className="form-control border-start-0" />
                                                                    <label>Footer (Optional)</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="footer" component="div" className="error" />
                                                    </div>
                                                </div>
                                                {/* Footer section end */}

                                                {/* Button section start */}
                                                <div className="col-12 ">
                                                    <div className="mb-2">
                                                        <div className="form-template mb-3 position-relative check-valid">
                                                            <div className="input-template input-template-lg">
                                                                <div className="form-floating">
                                                                    <Field as="select" id="buttonselect"
                                                                        value={values.buttonType}
                                                                        onChange={(e) => {

                                                                            const selectedValues = Array.from(e.target.selectedOptions, option => option.value);
                                                                            if (btnLimit.templateAddedBtn >= btnLimit.templateMaxButtonLimit) {
                                                                                toast.error(`You can add max ${btnLimit.templateMaxButtonLimit} buttons.`);
                                                                                return
                                                                            }

                                                                            // set preview initial value
                                                                            if (selectedValues.includes("quickReply") && !templatePreview["quickReplies"]) {
                                                                                setTemplatePreview(prev => ({
                                                                                    ...prev, quickReplies: [{
                                                                                        "replyButton": ""
                                                                                    }]
                                                                                }))

                                                                            } else if (selectedValues.includes("visitWebsite") && !templatePreview["visitWebsiteButtons"]) {
                                                                                setTemplatePreview(prev => ({
                                                                                    ...prev, visitWebsiteButtons: [{
                                                                                        "buttonText": "",
                                                                                        "websiteUrl": "",
                                                                                    }]
                                                                                }))

                                                                            } else if (selectedValues.includes("callPhoneNumber") && !templatePreview["callPhoneNumberButtons"]) {
                                                                                setTemplatePreview(prev => ({
                                                                                    ...prev, callPhoneNumberButtons: [{
                                                                                        "buttonText": "",
                                                                                        "countryCode": "",
                                                                                        "phoneNumber": ""
                                                                                    }]
                                                                                }))

                                                                            } else if (selectedValues.includes("copyOfferCode") && !templatePreview["copyOfferCodeButtons"]) {
                                                                                setTemplatePreview(prev => ({
                                                                                    ...prev, copyOfferCodeButtons: [{
                                                                                        "buttonText": "Copy offer code",
                                                                                        "offerCode": ""
                                                                                    }]
                                                                                }))
                                                                            }

                                                                            if (e.target.value === "call_to_action" && 0) {
                                                                                setFieldValue('buttonType', [e.target.value])
                                                                                setTemplatePreview(prevState => ({
                                                                                    ...prevState,
                                                                                    buttonType: [e.target.value],
                                                                                }))
                                                                            } else {
                                                                                setFieldValue('buttonType', selectedValues);
                                                                                setTemplatePreview(prevState => ({
                                                                                    ...prevState,
                                                                                    buttonType: selectedValues,
                                                                                }))
                                                                            }


                                                                            // Detect newly unselected items
                                                                            const removed = selectedButtonType.filter((value) => !selectedValues.includes(value));
                                                                            if (removed.includes("quickReply")) {
                                                                                setFieldValue('quickReplies', [{ "replyButton": "" }]);
                                                                                setTemplatePreview((prev) => {
                                                                                    const { quickReplies, ...rest } = prev; // Destructure to exclude quickReplies
                                                                                    return rest;
                                                                                });
                                                                            }
                                                                            if (removed.includes("visitWebsite")) {
                                                                                setFieldValue('visitWebsiteButtons', [{ "buttonText": "", "websiteUrl": "" }]);
                                                                                setTemplatePreview((prev) => {
                                                                                    const { visitWebsiteButtons, ...rest } = prev; // Destructure to exclude quickReplies
                                                                                    return rest;
                                                                                });
                                                                            }
                                                                            if (removed.includes("callPhoneNumber")) {
                                                                                setFieldValue('callPhoneNumberButtons', [{ "buttonText": "", "countryCode": "", "phoneNumber": "" }]);
                                                                                setTemplatePreview((prev) => {
                                                                                    const { callPhoneNumberButtons, ...rest } = prev; // Destructure to exclude quickReplies
                                                                                    return rest;
                                                                                });
                                                                            }
                                                                            if (removed.includes("copyOfferCode")) {
                                                                                setFieldValue('copyOfferCodeButtons', [{ "buttonText": "", "offerCode": "" }]);
                                                                                setTemplatePreview((prev) => {
                                                                                    const { copyOfferCodeButtons, ...rest } = prev; // Destructure to exclude quickReplies
                                                                                    return rest;
                                                                                });
                                                                            }

                                                                            // Update the selected values state
                                                                            setSelectedButtonType(selectedValues);

                                                                        }}
                                                                        multiple
                                                                        name="buttonType" className="form-control">
                                                                        <option value='' >None</option>
                                                                        <option value='quickReply'>Quick Reply (Max 10)</option>
                                                                        <option value='visitWebsite'>Visit Website (Max 2)</option>
                                                                        <option value='callPhoneNumber'>Call Phone Number (Max 1)</option>
                                                                        <option value='copyOfferCode'>Copy offer code (Max 1)</option>


                                                                    </Field>
                                                                    {/* <label>Button (Optional)</label> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage name="buttonType" component="div" className="error" />
                                                    </div>
                                                </div>

                                                <p className="mb-3"></p>
                                                {values.buttonType.includes('quickReply') && (
                                                    <FieldArray name="quickReplies">
                                                        {({ insert, remove, push }) => (
                                                            <div className="col-12 main-div-quick-reply" >
                                                                <h5>Quick Reply.......</h5>
                                                                {values.quickReplies && values.quickReplies.length > 0
                                                                    ? values.quickReplies.map((reply, index) => (
                                                                        <div className="row mb-4 margin-div" key={index} >
                                                                            <div className="col-12 col-md-6">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text"
                                                                                                    // name={`quickReplies.${index}`}
                                                                                                    name={`quickReplies[${index}].replyButton`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`quickReplies[${index}].replyButton`, e.target.value);

                                                                                                        // Update the quickReplyPreview array
                                                                                                        const newPreview = [...templatePreview.quickReplies];
                                                                                                        newPreview[index] = { replyButton: e.target.value }
                                                                                                        setTemplatePreview(prev => ({ ...prev, quickReplies: newPreview }));
                                                                                                    }}
                                                                                                    className="form-control border-start-0"
                                                                                                    maxLength="25"
                                                                                                />
                                                                                                <label>Button{index + 1}<span className='star'>*</span></label>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className='button-length-error'>
                                                                                    <p>{`${reply['replyButton'].length}/25`}</p>


                                                                                    <ErrorMessage name={`quickReplies[${index}].replyButton`} component="div" className="error" />

                                                                                </div>

                                                                            </div>
                                                                            {values.quickReplies.length > 1 && (
                                                                                <div className="col-12 col-md-2 ">
                                                                                    <button className="btn btn-sm btn-danger " type="button"
                                                                                        onClick={() => {
                                                                                            remove(index);

                                                                                            // Update the quickReplyPreview array
                                                                                            const newPreview = [...templatePreview.quickReplies];
                                                                                            let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                            setTemplatePreview(prev => ({ ...prev, quickReplies: newPreviewData }));

                                                                                        }} >X</button>
                                                                                </div>
                                                                            )}

                                                                            {values.quickReplies.length - 1 === index && btnLimit.quickReplyAddBtn > 0 && (
                                                                                <div className="col-12 col-md-4 ">
                                                                                    <button className="btn btn-sm btn-info" type="button"
                                                                                        onClick={() => {
                                                                                            push({ replyButton: '' })
                                                                                            setTemplatePreview(prev => ({ ...prev, quickReplies: [...prev.quickReplies, { replyButton: '' }] }));

                                                                                        }}>Add Quick Reply +{btnLimit.quickReplyAddBtn}</button>
                                                                                </div>
                                                                            )}


                                                                        </div>
                                                                    ))
                                                                    : null}


                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                )}
                                                {values.buttonType.includes('visitWebsite') && (
                                                    <FieldArray name="visitWebsiteButtons">
                                                        {({ insert, remove, push }) => (
                                                            <div className="col-12 main-div-visit-website" style={{ backgroundColor: 'lightcoral' }}>
                                                                <h5>Visit Website.....</h5>
                                                                {values.visitWebsiteButtons && values.visitWebsiteButtons.length > 0
                                                                    ? values.visitWebsiteButtons.map((visitWebsiteButton, index) => (
                                                                        <div className="row mb-4 margin-div" key={index}>
                                                                            <div className="col-12 col-md-5">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text"
                                                                                                    name={`visitWebsiteButtons[${index}].buttonText`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`visitWebsiteButtons[${index}].buttonText`, e.target.value);

                                                                                                        const newPreview = [...templatePreview.visitWebsiteButtons];
                                                                                                        newPreview[index] = { ...newPreview[index], buttonText: e.target.value }
                                                                                                        setTemplatePreview(prev => ({ ...prev, visitWebsiteButtons: newPreview }));

                                                                                                    }}
                                                                                                    className="form-control border-start-0"
                                                                                                    maxLength="25"
                                                                                                />
                                                                                                <label>Button Text {index + 1}<span className='star'>*</span></label>

                                                                                            </div>
                                                                                            <div className='button-length-error'>
                                                                                                {`${visitWebsiteButton['buttonText'].length}/25`}
                                                                                                <ErrorMessage name={`visitWebsiteButtons[${index}].buttonText`} component="div" className="error" />

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-5">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text"
                                                                                                    name={`visitWebsiteButtons[${index}].websiteUrl`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`visitWebsiteButtons[${index}].websiteUrl`, e.target.value);

                                                                                                        const newPreview = [...templatePreview.visitWebsiteButtons];
                                                                                                        newPreview[index] = { ...newPreview[index], websiteUrl: e.target.value }
                                                                                                        setTemplatePreview(prev => ({ ...prev, visitWebsiteButtons: newPreview }));

                                                                                                    }}
                                                                                                    className="form-control border-start-0"

                                                                                                />
                                                                                                <label>Website Url {index + 1}<span className='star'>*</span></label>

                                                                                            </div>
                                                                                            <div className='button-length-error'>
                                                                                                {`${visitWebsiteButton['websiteUrl'].length}/2000`}

                                                                                                <ErrorMessage name={`visitWebsiteButtons[${index}].websiteUrl`} component="div" className="error" />

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {values.visitWebsiteButtons.length > 1 && (
                                                                                <div className="col-12 col-md-2 ">
                                                                                    <button className="btn btn-sm btn-danger " type="button"
                                                                                        onClick={() => {
                                                                                            remove(index);
                                                                                            // Update the Preview array
                                                                                            const newPreview = [...templatePreview.visitWebsiteButtons];
                                                                                            let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                            setTemplatePreview(prev => ({ ...prev, visitWebsiteButtons: newPreviewData }));

                                                                                        }} >X</button>
                                                                                </div>
                                                                            )}


                                                                            {values.visitWebsiteButtons.length - 1 === index && btnLimit.visitWebsiteAddBtn > 0 && (
                                                                                <div className="col-12 col-md-2 ">
                                                                                    <button className="btn btn-sm btn-info" type="button"
                                                                                        onClick={() => {
                                                                                            push({ buttonText: '', websiteUrl: '' })
                                                                                            setTemplatePreview(prev => ({ ...prev, visitWebsiteButtons: [...prev.visitWebsiteButtons, { buttonText: '', websiteUrl: '' }] }))
                                                                                        }}>Add Website Url +{btnLimit.visitWebsiteAddBtn}</button>
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                    ))
                                                                    : null}


                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                )}
                                                {values.buttonType.includes('callPhoneNumber') && (
                                                    <FieldArray name="callPhoneNumberButtons">
                                                        {({ insert, remove, push }) => (
                                                            <div className="col-12 main-div-call-phone" >
                                                                <h5>Call Phone Number.....</h5>
                                                                {values.callPhoneNumberButtons && values.callPhoneNumberButtons.length > 0
                                                                    ? values.callPhoneNumberButtons.map((callPhoneNumberButton, index) => (
                                                                        <div className="row mb-4" key={index}>
                                                                            <div className="col-12 col-md-5">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text"
                                                                                                    name={`callPhoneNumberButtons[${index}].buttonText`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`callPhoneNumberButtons[${index}].buttonText`, e.target.value);

                                                                                                        const newPreview = [...templatePreview.callPhoneNumberButtons];
                                                                                                        newPreview[index] = { ...newPreview[index], buttonText: e.target.value }
                                                                                                        setTemplatePreview(prev => ({ ...prev, callPhoneNumberButtons: newPreview }));

                                                                                                    }}
                                                                                                    className="form-control border-start-0"
                                                                                                    maxLength="25"
                                                                                                />
                                                                                                <label>Button Text {index + 1}<span className='star'>*</span></label>

                                                                                            </div>
                                                                                            <div className='button-length-error'>

                                                                                                {`${callPhoneNumberButton['buttonText'].length}/25`}
                                                                                                <ErrorMessage name={`callPhoneNumberButtons[${index}].buttonText`} component="div" className="error" />

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-3">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text"
                                                                                                    name={`callPhoneNumberButtons[${index}].countryCode`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`callPhoneNumberButtons[${index}].countryCode`, e.target.value);

                                                                                                        const newPreview = [...templatePreview.callPhoneNumberButtons];
                                                                                                        newPreview[index] = { ...newPreview[index], countryCode: e.target.value }
                                                                                                        setTemplatePreview(prev => ({ ...prev, callPhoneNumberButtons: newPreview }));

                                                                                                    }}
                                                                                                    maxLength="5"
                                                                                                    className="form-control border-start-0"

                                                                                                />
                                                                                                <label>Country Code {index + 1}<span className='star'>*</span></label>

                                                                                            </div>
                                                                                            <div className='button-length-error'>
                                                                                                {`${callPhoneNumberButton['countryCode'].length}/5`}
                                                                                                <ErrorMessage name={`callPhoneNumberButtons[${index}].countryCode`} component="div" className="error" />

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-md-4">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text"
                                                                                                    name={`callPhoneNumberButtons[${index}].phoneNumber`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`callPhoneNumberButtons[${index}].phoneNumber`, e.target.value);

                                                                                                        const newPreview = [...templatePreview.callPhoneNumberButtons];
                                                                                                        newPreview[index] = { ...newPreview[index], phoneNumber: e.target.value }
                                                                                                        setTemplatePreview(prev => ({ ...prev, callPhoneNumberButtons: newPreview }));

                                                                                                    }}
                                                                                                    maxLength="20"
                                                                                                    className="form-control border-start-0"

                                                                                                />
                                                                                                <label>Phone Number {index + 1}<span className='star'>*</span></label>

                                                                                            </div>
                                                                                            <div className='button-length-error'>

                                                                                                {`${callPhoneNumberButton['phoneNumber'].length}/20`}
                                                                                                <ErrorMessage name={`callPhoneNumberButtons[${index}].phoneNumber`} component="div" className="error" />

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {values.callPhoneNumberButtons.length > 1 && (
                                                                                <div className="col-12 col-md-2 ">
                                                                                    <button className="btn btn-sm btn-danger " type="button"
                                                                                        onClick={() => {
                                                                                            remove(index);
                                                                                            // Update the Preview array
                                                                                            const newPreview = [...templatePreview.callPhoneNumberButtons];
                                                                                            let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                            setTemplatePreview(prev => ({ ...prev, callPhoneNumberButtons: newPreviewData }));

                                                                                        }} >X</button>
                                                                                </div>
                                                                            )}


                                                                            {values.callPhoneNumberButtons.length - 1 === index && btnLimit.callPhoneNumberAddBtn > 0 && (
                                                                                <button className="btn btn-sm btn-info" type="button"
                                                                                    onClick={() => {
                                                                                        push({ "buttonText": "", "countryCode": "", "phoneNumber": "" })
                                                                                        setTemplatePreview(prev => ({ ...prev, callPhoneNumberButtons: [...prev.callPhoneNumberButtons, { "buttonText": "", "countryCode": "", "phoneNumber": "" }] }))
                                                                                    }}>Add Phone Number +{btnLimit.callPhoneNumberAddBtn}</button>
                                                                            )}

                                                                        </div>
                                                                    ))
                                                                    : null}


                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                )}
                                                {values.buttonType.includes('copyOfferCode') && (
                                                    <FieldArray name="copyOfferCodeButtons">
                                                        {({ insert, remove, push }) => (
                                                            <div className="col-12 main-div-copy-offer" >
                                                                <h5>Copy Offer Code.....</h5>
                                                                {values.copyOfferCodeButtons && values.copyOfferCodeButtons.length > 0
                                                                    ? values.copyOfferCodeButtons.map((copyOfferCodeButton, index) => (
                                                                        <div className="row mb-4 margin-div" key={index}>

                                                                            <div className="col-12 col-md-8">
                                                                                <div className="mb-2">
                                                                                    <div className="form-template mb-3 position-relative check-valid">
                                                                                        <div className="input-template input-template-lg">
                                                                                            <div className="form-floating">
                                                                                                <Field type="text"
                                                                                                    name={`copyOfferCodeButtons[${index}].offerCode`}
                                                                                                    onChange={(e) => {
                                                                                                        // Update the field value
                                                                                                        setFieldValue(`copyOfferCodeButtons[${index}].offerCode`, e.target.value);

                                                                                                        const newPreview = [...templatePreview.copyOfferCodeButtons];
                                                                                                        newPreview[index] = { ...newPreview[index], offerCode: e.target.value }
                                                                                                        setTemplatePreview(prev => ({ ...prev, copyOfferCodeButtons: newPreview }));

                                                                                                    }}
                                                                                                    maxLength="15"
                                                                                                    className="form-control border-start-0"

                                                                                                />
                                                                                                <label>Copy Offer Code {index + 1}<span className='star'>*</span></label>

                                                                                            </div>
                                                                                            <div className='button-length-error'>
                                                                                                {`${copyOfferCodeButton['offerCode'].length}/15`}

                                                                                                <ErrorMessage name={`copyOfferCodeButtons[${index}].offerCode`} component="div" className="error" />

                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {values.copyOfferCodeButtons.length > 1 && (
                                                                                <div className="col-12 col-md-2 ">
                                                                                    <button className="btn btn-sm btn-danger " type="button"
                                                                                        onClick={() => {
                                                                                            remove(index);
                                                                                            // Update the Preview array
                                                                                            const newPreview = [...templatePreview.copyOfferCodeButtons];
                                                                                            let newPreviewData = newPreview.filter((val, loopIndex) => loopIndex !== index)
                                                                                            setTemplatePreview(prev => ({ ...prev, copyOfferCodeButtons: newPreviewData }));

                                                                                        }} >X</button>
                                                                                </div>
                                                                            )}


                                                                            {values.copyOfferCodeButtons.length - 1 === index && btnLimit.copyOfferCodeAddBtn > 0 && (
                                                                                <button className="btn btn-sm btn-info" type="button"
                                                                                    onClick={() => {
                                                                                        push({ buttonText: 'Copy offer code', offerCode: '' })
                                                                                        setTemplatePreview(prev => ({ ...prev, copyOfferCodeButtons: [...prev.copyOfferCodeButtons, { buttonText: '', websiteUrl: '' }] }))
                                                                                    }}>Add Copy Offer Code +{btnLimit.copyOfferCodeAddBtn}</button>
                                                                            )}

                                                                        </div>
                                                                    ))
                                                                    : null}


                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                )}
                                                {/* Button section end */}

                                                <div style={{ display: 'flex' }}>
                                                    <button style={{ height: '36px', marginRight: '30px' }} className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                                </div>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <br />


                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            {/* Template Form section end */}

                            {/* Template Preview section start */}
                            <div className="col-12 col-md-4">
                                <TemplatePreview templatePreview={templatePreview} />
                            </div>
                            {/* Template Preview section end */}
                        </div>
                    </div>
                    <div className='row'>
                        <div className=' className="col-6 col-md-6"'>

                        </div>
                        <div className='className="col-6 col-md-6"'>

                        </div>
                    </div>

                </div>
            </main >
        </div>
        <Footer />

    </>)
}

export default BroadcastTemplateFormButtonEdit;