import React, { useCallback, useEffect, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import * as Icon from 'react-bootstrap-icons';


function TextUpdaterNode({ data, isConnectable }) {
    console.log('isConnectable', isConnectable);

    const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

    const handleCloseMenu = () => {
        setIsOpenContextMenu(false); // Hide the context menu
    };

    const handleOpenMenu = () => {
        setIsOpenContextMenu(true);
    };

    // useEffect(()=>{
    //     console.log('isOpenContextMenu',isOpenContextMenu);

    // },[isOpenContextMenu])


    const onChange = useCallback((evt) => {
        console.log(evt.target.value);
        if (data.onUpdate) {
            data.onUpdate(data.id, evt.target.value);
        }
    }, [data]);

    const onRemove = useCallback(() => {
        if (data.onRemove) {
            console.log('data.id', data.id);

            data.onRemove(data.id);
        }
    }, [data]);

    const handleStyle = { top: 50 };


    return (
        <div className="custom-node">
            {/* create connection bullets */}
            {data.type === 'startNode' ? (<>
                <Handle id="a" style={{top:70}}  className="node-handle"  type="source" position={Position.Right} />
                <Handle id="b" style={{top:100}} className="node-handle"  type="source" position={Position.Right} />
                <Handle id="c" style={{top:130}} className="node-handle"  type="source" position={Position.Right} />

            </>) : (<>
                <Handle type="target" position={Position.Left} />
                <Handle type="source" position={Position.Right} />
            </>)}

            {/* create cart header */}
            <div className='chatbot_template_header'>
                <div>
                    {data.leftButtonClick === "InteractiveButton" ? (
                        <>
                            <Icon.RecordCircle size={20} />
                            <h6>Buttons</h6>
                        </>
                    ) :
                        data.leftButtonClick === "InteractiveList" ? (
                            <>
                                <Icon.ListCheck size={20} />
                                <h6>List</h6>
                            </>
                        ) :
                            ''}
                </div>
                <div className='context-menu-icon'
                    onClick={handleOpenMenu}
                >
                    <Icon.ThreeDotsVertical size={20} />
                </div>
                {/* context menu items */}
                <ul className={`context-menu-item-ul ${isOpenContextMenu ? 'active' : ''}`}
                    onClick={handleCloseMenu}
                >
                    <li style={{ padding: "5px 10px", cursor: "pointer" }}><Icon.Pencil size={10} /> Edit</li>
                    <li style={{ padding: "5px 10px", cursor: "pointer" }}
                        onClick={() => onRemove()}
                    ><Icon.Trash size={10} /> Delete</li>
                </ul>

            </div>
            <div dangerouslySetInnerHTML={{ __html: data.htmlContent }} />

            {/* cart button design */}
            <div className='node-conn-btns'>
                <div className='conn-btn-item'>
                    Button 01
                </div>
                <div className='conn-btn-item'>
                    Button 02
                </div>
                <div className='conn-btn-item'>
                    Button 03
                </div>
            </div>
        </div>
    );
}

export default TextUpdaterNode;
