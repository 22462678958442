import React, { useState } from 'react';
import axios from 'axios';

const BuySubscription = () => {
    const [planId, setPlanId] = useState('');
    const [email, setEmail] = useState('');
    const [totalCount, setTotalCount] = useState(12);

    const handleSubscription = async () => {
        try {
            const response = await axios.post('http://localhost:5000/create-subscriptions', {
                plan_id: planId,
                customer_email: email,
                total_count: totalCount,
                customer_contact: "8596748596"
            });

            const subscriptionData = response.data;

            const options = {
                key: 'P6R33ofVhnQ0pWwgUHVChcdM', // Replace with your Razorpay key
                subscription_id: subscriptionData.id, // Razorpay subscription ID
                name: 'Growwup Subscriptions',
                description: 'Access premium features with this subscription plan.',
                handler: function (response) {
                    alert('Payment Successful');
                    console.log('Payment response:', response);
                },
                prefill: {
                    email: email,
                },
                theme: {
                    color: '#3399cc',
                },
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();
        } catch (error) {
            console.error('Error creating subscription:', error.response?.data || error.message);
            alert('Error creating subscription. Please try again.');
        }
    };

    return (
        <div>
            <h2>Buy Subscription</h2>
            <input
                type="text"
                placeholder="Enter Plan ID"
                value={planId}
                onChange={(e) => setPlanId(e.target.value)}
            />
            <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="number"
                placeholder="Total Count (e.g., 12)"
                value={totalCount}
                onChange={(e) => setTotalCount(e.target.value)}
            />
            <button onClick={handleSubscription}>Buy Subscription</button>
        </div>
    );
};

export default BuySubscription;
