import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
// import { io } from 'socket.io-client';
import { toast } from 'react-toastify';
import Header from '../Website/Header';
import Footer from './Footer';
import { getBroadcastCount } from '../../api/broadcastApi';
import { getGroupCount } from '../../api/groupApi';
import { getTemplateCount } from '../../api/templateApi';

import { useAuthContext } from '../../context/AuthContext';
import { signupEmbededUser } from '../../api/authApi';
import Loader from '../common/Loader';
import { getUserDetails } from '../../api/userApi';
import Sidebar from './Sidebar';

function Dashboard() {
    const { logoutUser, userData } = useAuthContext();
    const [sidebarHideShow, setSidebarHideShow] = useState(true);
    const [broadcastCount, setBroadcastCount] = useState({});
    const [groupCount, setGroupCount] = useState({});
    const [templateCount, setTemplateCount] = useState();
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);


    const handleBroadcastCount = async () => {
        // setLoading(true);
        try {
            const responceData = await getBroadcastCount();
            setBroadcastCount(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            // setLoading(false);
        }
    }
    const handleGroupCount = async () => {
        // setLoading(true);
        try {
            const responceData = await getGroupCount();
            setGroupCount(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            // setLoading(false);
        }
    }
    const handleTemplateCount = async () => {
        // setLoading(true);
        try {
            const responceData = await getTemplateCount();
            console.log('responceData.data', responceData.data)
            setTemplateCount(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            // setLoading(false);
        }
    }

    const handleUserInfo = async () => {
        setLoading(true);
        try {
            const responceData = await getUserDetails();
            console.log('responceData.data', responceData.data)
            setUserInfo(responceData.data);
        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'No data found.';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const handleSignupEmbededUser = async (code) => {
        setLoading(true);
        try {
            let postData = {
                code: code
            }
            const responceData = await signupEmbededUser(postData);
            toast.success('Whatsapp Business API Applied Sucessfully');
            handleUserInfo();
            // setTimeout(() => {
            //     window.location.href = process.env.REACT_APP_FRONTEND_BASE_URL+'dashboard'
            // }, 1000);

            // console.log('handleSignupEmbededUser::responceData.data', responceData.data)

        } catch (error) {
            if (error.response?.status == 401) {
                logoutUser();
            } else {
                const errorMessage = error.response?.data?.ErrorMessage || 'Embeded signup failed.';
                toast.error(errorMessage);
            }

        } finally {
            setLoading(false);
        }
    }

    const initializeEmbededSignup = () => {
        window.fbAsyncInit = function () {
            // JavaScript SDK configuration and setup
            window.FB.init({
                appId: '497078262906011', // Facebook App ID
                cookie: true, // enable cookies
                xfbml: true, // parse social plugins on this page
                version: 'v21.0' //Graph API version
            });
        };

        // Load the JavaScript SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        // Session logging message event listener
        window.addEventListener('message', (event) => {

            if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") return;
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    console.log('message event: ', data); // remove after testing
                    // your code goes here
                }
            } catch {
                console.log('message event: ', event.data); // remove after testing
                // your code goes here
            }
        });
    }


    // Facebook Login with JavaScript SDK
    function launchWhatsAppSignup() {
        // Conversion tracking code
        // fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup' });

        // Launch Facebook login
        window.FB.login(function (response) {
            if (response.authResponse) {
                const code = response.authResponse.code;
                handleSignupEmbededUser(code)
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: process.env.REACT_APP_EMBEDED_CONFIG_ID, // configuration ID goes here
            response_type: 'code',    // must be set to 'code' for System User access token
            override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
            scope: 'whatsapp_business_management,business_management,catalog_management,public_profile,email',
            extras: {
                setup: {
                }
            }
        });
    }
    useEffect(() => {
        initializeEmbededSignup();
        handleBroadcastCount()
        handleGroupCount()
        handleTemplateCount()
        handleUserInfo()
    }, []);
    return (<>
        {/* <Header /> */}
        {loading && <Loader />}
        {/* <main className="main mainheight"> */}
        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "padding-top": "calc(env(safe-area-inset-top) + 84.7167px)" }}>
                <div className="container">
                    <div className=" row top-row" >
                        {userInfo && userInfo.statusEnum !== "Approve" && (
                            <div className='col-12 col-md-12 col-lg-12 embed-div'>
                                <button onClick={(e) => launchWhatsAppSignup()} className='btn btn-theme btn-md fb'>Apply for whatsapp business API</button>
                            </div>
                        )}

                        <div className="col-12 col-md-6 col-lg-4">

                            <div className="card border-0 mb-4 theme-blue bg-gradient-theme-light">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <figure className="avatar avatar-50 rounded-circle bg-yellow text-white">
                                                <i className="bi bi-star mb-1 vm"></i>
                                            </figure>
                                        </div>
                                        <div className="col ps-0">
                                            <h6 className="mb-0">{userInfo?.businessName} </h6>
                                            <p className="fw-normal">{userInfo?.name}</p>
                                        </div>
                                        <div className="col-auto">
                                            {/* <h4 className="mb-0"> {userInfo?.statusEnum==""?'-':userInfo?.statusEnum}</h4> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className=" row align-items-center">
                                        <div className="col-auto">
                                            <p className="mb-0">Mobile No: {`${userInfo?.mobileCode}${userInfo?.mobileNo}`}</p>
                                            <p className="mb-0">Email ID: {userInfo?.email}</p>
                                            <p className="text-muted">Meta API Status: {userInfo?.statusEnum == "" ? <span style={{ color: 'darkorange' }}>Pending</span> : <span style={{ color: 'darkgreen' }}>{userInfo?.statusEnum}</span>}</p>
                                        </div>
                                        {/* <div className="col text-end">
                                        <a href="subscription.html" className="btn btn-sm btn-theme">View Plan!</a>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">

                            <div className="card border-0 mb-4 theme-blue bg-gradient-theme-light">
                                <div className="card-header ">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className=" rounded bg-light-theme">
                                                <Icon.Broadcast className='dashboradIcon' />

                                            </div>
                                        </div>
                                        <div className="col">
                                            <h6 className="fw-medium mb-0">Broadcast</h6>
                                        </div>
                                        <div className="col-auto">
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className="circle-small">
                                                <div id="circleprogressblue1"></div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            {(broadcastCount != '' && broadcastCount != undefined) ? (
                                                <h5 className="mb-0">{broadcastCount.countbroadcast} </h5>

                                            ) : (
                                                <h5 className="mb-0">0 </h5>

                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card border-0 mb-4 theme-green bg-gradient-theme-light">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className=" rounded bg-light-theme">
                                                <i className="bi bi-receipt-cutoff h5"></i>
                                                <Icon.CollectionFill className='dashboradIcon' />

                                            </div>
                                        </div>
                                        <div className="col">
                                            <h6 className="fw-medium mb-0">Group</h6>
                                        </div>
                                        <div className="col-auto">
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className="circle-small">
                                                <div id="circleprogressgreen1"></div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            {(groupCount != '' && groupCount != undefined) ? (
                                                <h5 className="mb-0">{groupCount.countGroup} </h5>

                                            ) : (
                                                <h5 className="mb-0">0 </h5>

                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card border-0 mb-4 theme-red bg-gradient-theme-light">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className=" rounded bg-light-red">
                                                <Icon.FileEarmarkRichtext className='dashboradIcon' />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <h6 className="fw-medium mb-0">Templates</h6>
                                        </div>
                                        <div className="col-auto">
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="card-body">
                                <div className="row">
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#ticketbookmovie">
                                            <div className="card-body">
                                                <i className="bi bi-film h5 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Approved</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#ticketbookflight">
                                            <div className="card-body">
                                                <i className="bi bi-send h5 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">In-Review</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col text-center">
                                        <a href="#" className="card border-0" data-bs-toggle="modal" data-bs-target="#ticketbookbus">
                                            <div className="card-body">
                                                <i className="bi bi-truck h4 avatar avatar-40 rounded text-theme mb-2"></i>
                                                <p className="text-secondary small">Rejected</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                                <div className="card-footer">
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <div className="circle-small">
                                                <div id="circleprogressred1"></div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            {(templateCount != '' && templateCount != undefined) ? (
                                                <h5 className="mb-0">{templateCount} </h5>

                                            ) : (
                                                <h5 className="mb-0">0 </h5>

                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div >

            </main >
        </div>
        {/* <Footer /> */}
    </>)
}

export default Dashboard;