// src/api/authApi.js
import axiosInstance from './axiosInstance';

export const getLastMessageUser = async (postData) => {
    // console.log('getLastMessageUser:',postData);
    
    try {
        const response = await axiosInstance.get('/last-message-user', {
            params:postData
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const getMessageApi = async (postData) => {
    try {
        const response = await axiosInstance.get('/get-message', {
            params: postData
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const sendMessageApi = async (postData) => {
    try {
        const response = await axiosInstance.post('/send-message', postData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUserLastMsgApi = async (postData) => {
    try {
        const response = await axiosInstance.get('/last-message-by-contactId', {
            params: postData
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};





