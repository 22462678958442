import React, { useCallback, useState } from 'react';
import {
    ReactFlow,
    addEdge,
    useNodesState,
    useEdgesState,
    MiniMap,
    Background,
    Controls,
    Panel,
    Position,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import Header from '../Header';
import Loader from '../../common/Loader';
import TextUpdaterNode from './TextUpdaterNode';
import { useNavigate } from 'react-router-dom';
import './Drawflow.css'
import * as Icon from 'react-bootstrap-icons';


const ChatBotFlow = () => {
    const [sidebarHideShow, setSidebarHideShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [colorMode, setColorMode] = useState('light');
    const removeNode = (id) => {
        console.log('id', id);

        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
    };

    const updateNodeLabel = (id, newLabel) => {
        setNodes((nds) =>
            nds.map((node) => (node.id === id ? { ...node, data: { ...node.data, label: newLabel } } : node))
        );
    };
    const initialNodes = [
        {
            id: "1",
            type: "input",
            position: { x: 100, y: 100 },
            data: {
                id: "1",
                type: "startNode",
                leftButtonClick: "InteractiveButton",
                htmlContent: `<p>Ask a question here</p>`,
                onRemove: removeNode,
                onUpdate: updateNodeLabel,
            },
        },
        {
            id: "2",
            type: "output",
            position: { x: 400, y: 100 },
            data: {
                id: "2",
                type: "middleNode",
                leftButtonClick: "InteractiveList",
                htmlContent: ``,
                onRemove: removeNode,
                onUpdate: updateNodeLabel,
            },
        },
    ];

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const toggleSidebar = () => {
        setSidebarHideShow((prevState) => !prevState);
    };

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges]
    );

    const handleColorModeChange = (event) => {
        setColorMode(event.target.value);
    };

    const handleInit = (instance) => {
        instance.setViewport({ x: 0, y: 0, zoom: 1.2 }); // Programmatically set viewport
    };

    const addNewNode = (buttonClicked) => {
        const id = `${nodes.length + 1}`;
        const newNode = {
            id,
            type: 'output',
            position: { x: Math.random() * 400, y: Math.random() * 400 },
            data: {
                id: id,
                type: nodes.length == 0 ? "startNode" : "middleNode",
                leftButtonClick: buttonClicked,
                htmlContent: ``,
                onRemove: removeNode,
                onUpdate: updateNodeLabel,
            }
        };
        setNodes((nds) => [...nds, newNode]);
    };



    return (
        <>
            <Header toggleSidebar={toggleSidebar} sidebarHideShow={sidebarHideShow} />
            {loading && <Loader />}

            <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`}>
                <div className="sidebar-wrap" style={{ marginTop: '50px', marginLeft: '1px' }}>
                    <div className="sidebar">
                        <div className="container" style={{ padding: '0 10px' }}>
                            <div className="row mb-4">
                                <div className="col-12 px-0">
                                    <ul className="nav nav-pills" style={{ borderRadius: '5px' }}>
                                        <li
                                            className="nav-item"
                                            style={{ borderRadius: '5px', paddingLeft: '10px', backgroundColor: '#28cb28' }}
                                            onClick={() => addNewNode('InteractiveButton')}
                                        >
                                            <a className="nav-link">

                                                <div className="col">
                                                    <Icon.RecordCircle size={20} /> Buttons</div>
                                            </a>
                                        </li>
                                        <li
                                            className="nav-item"
                                            style={{ borderRadius: '5px', paddingLeft: '10px', backgroundColor: '#f60808' }}
                                            onClick={() => addNewNode('InteractiveList')}
                                        >
                                            <a className="nav-link">
                                                <div className="col"><Icon.ListCheck size={20} /> List</div>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <main className="main mainheight" style={{ paddingTop: 'calc(env(safe-area-inset-top) + 100px)' }}>
                    <div className="container" style={{ padding: '0' }}>
                        <div style={{ width: '85vw', height: '90vh' }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                colorMode={colorMode}
                                fitView
                                nodeTypes={{ input: TextUpdaterNode, output: TextUpdaterNode }}
                                onInit={handleInit}

                            >
                                <MiniMap />
                                <Background />
                                <Controls />

                                <Panel position="top-right">
                                    <select onChange={handleColorModeChange} data-testid="colormode-select">
                                        <option value="dark">dark</option>
                                        <option value="light">light</option>
                                        <option value="system">system</option>
                                    </select>
                                </Panel>
                            </ReactFlow>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default ChatBotFlow;
