// src/api/authApi.js
import axiosInstance from './axiosInstance';



export const getTicketList = async (postData) => {
    try {
        const response = await axiosInstance.get('/get-all-ticket', {
            params: postData
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const getGroupTicketList = async (postData) => {
    try {
        const response = await axiosInstance.get('/get-group-by-ticket', {
            params: postData
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
/* assign-ticket-in-group form
  let postData = {
                ticketId: ticketId,
                groupId: groupId,
}*/
export const assignTicketInGroup = async (postData) => {
    try {
        const response = await axiosInstance.post('/assign-ticket-in-group', postData);
        return response.data;
    } catch (error) {
        throw error;
    }
};
