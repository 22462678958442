import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Header from '../../Website/Header';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';
import { groupInitialValues, groupSchema } from '../../../utils/validationSchema';
import { addGroup } from '../../../api/groupApi';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../../context/AuthContext';
import Loader from '../../common/Loader';
import Sidebar from '../Sidebar';


function GroupForm() {
    const [loading, setLoading] = useState(false);
    const [sidebarHideShow, setSidebarHideShow] = useState(true);
    const { userData } = useAuthContext();
    const navigate = useNavigate();
    const handleSubmit = async (formData) => {
        setLoading(true);
        try {
            let postData = {
                name: formData.groupName,
                description: formData.description,
                createdby: userData._id
            }
            let responceData = await addGroup(postData);
            toast.success('Group created successful!');
            navigate('/group')

        } catch (error) {
            const errorMessage = error.response?.data?.ErrorMessage || 'Create group failed';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };
    return (<>
        {/* <Header /> */}
        {loading && <Loader />}
        {/* <main className="main mainheight"> */}
        <div className={`sidebar-pushcontent sidebar-filled ${sidebarHideShow ? '' : 'menu-close'}`} >

            <Sidebar sidebarHideShow={sidebarHideShow} setSidebarHideShow={setSidebarHideShow} />



            <main className="main mainheight" style={{ "padding-top": "calc(env(safe-area-inset-top) + 84.7167px)" }}>
                <div className="container-fluid mb-4">
                    <div className="row align-items-center page-title">
                        <div className="col col-sm-auto">
                            <div className="input-group input-group-md">
                                <span className="input-group-text text-secondary bg-none" >Add Group</span>
                            </div>
                        </div>

                        <div className="col-auto sm-auto">

                            {/* <div className="btn btn-link btn-square text-secondary"> */}
                            <button onClick={(e) => navigate('/group')} className="btn btn-theme btn-md" >Back</button>
                            {/* </div> */}

                        </div>
                    </div>
                </div>
                <div className="container">

                    {/* <div className='headering-back-div'>
                        <h5 className="title"></h5>
                        <button onClick={(e) => navigate('/group')} className="btn btn-theme btn-md backButton" ></button>
                    </div> */}
                    <div className="row justify-content-center mb-2">
                        <Formik
                            initialValues={groupInitialValues}
                            validationSchema={groupSchema}
                            onSubmit={handleSubmit}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="col-12 col-lg-6">
                                        <div className="mb-2">
                                            <div className="form-group mb-3 position-relative check-valid">
                                                <div className="input-group input-group-lg">
                                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                    <div className="form-floating">
                                                        <Field type="text" name="groupName" className="form-control border-start-0" />
                                                        <label>Group Name <span className='star'>*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorMessage name="groupName" component="div" className="error" />
                                        </div>
                                        <div className="mb-2">
                                            <div className="form-group mb-3 position-relative check-valid">
                                                <div className="input-group input-group-lg">
                                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                                                    <div className="form-floating">
                                                        <Field as="textarea" name="description" className="form-control border-start-0" />
                                                        <label>Description <span className='star'>*</span></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorMessage name="description" component="div" className="error" />
                                        </div>
                                        <button className="btn btn-theme" type="submit" disabled={isSubmitting}>Save</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </main>
        </div>
        // <Footer />

    </>)
}

export default GroupForm;